.loader {
  border: 5px solid #fff;
  border-top: 5px solid #32B265;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 45%;
  left: 48%;
  z-index: 1302;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.appOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 10px;
  opacity: 0.5;
  z-index: 1301;
}
